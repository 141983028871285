import { FC, useContext, useEffect, MouseEvent } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";

import DropdownStatusColorMeaning from "../dropdownStatusColorMeaning";

import { AuthContext } from "src/context/authContext";
import { LoaderContext } from "src/context/loaderContext";
import { ToastContext } from "src/context/toastContext";
import useFetch from "src/hooks/useFetch";

import { RepriseReimbursementResourcesLogo} from "src/assets";
import { AuthenticatedHCPHeader, AuthenticatedManufactureHeader } from "src/constants/headerLinks";
import { HeaderElementType, ToastTypes } from "src/types";
import { APIEndpoints } from "src/types/apiTypes";
import { LogoutAPISuccessResponseType } from "./types";
import {FAX_NUMBER, PHONE_NUMBER} from "../../constants/contact";



const AuthHeader: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { userData, destroySession, isManufacturer, isUserAuthorized } = useContext(AuthContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const { showToast } = useContext(ToastContext);





  const { mutate, error, data, removeErrorAndData } = useFetch<LogoutAPISuccessResponseType>(APIEndpoints.USER_LOGOUT);

  useEffect(() => {
    if (data || error) {
      hideLoader();
      if (data && data.status === 200) {
        destroySession();
        showToast([data.message], ToastTypes.SUCCESS);
        navigate('/users/sign_in');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  const logoutUser = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if ((error && error.length > 0) || data) removeErrorAndData();

    setLoader();
    mutate();
  };



  return (
    <nav className="navbar navbar-expand-lg p-3 pt-2 pb-2">
      <Link className="navbar-brand" to={isUserAuthorized ?
            (userData?.is_password_changed ?
              (isManufacturer ? `/statistics` : `/cases`) :
              '/users/createuser'
            ) :
            `/users/sign_in`
        }
      >
          <img className="img-fluid after-login-logo" src={RepriseReimbursementResourcesLogo} alt="Site logo"/>
      </Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#authHeader">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="authHeader">
        <ul className="navbar-nav me-auto first-nav">
          {userData?.is_password_changed ? (
            <>
              {isManufacturer === true ?
                AuthenticatedManufactureHeader.map((headerLink, index) => (
                  <li
                    className={`nav-item ${headerLink.actionType === HeaderElementType.DRPODOWN ? 'dropdown' : ''}`}
                    key={index}
                  >
                    {headerLink.actionType === HeaderElementType.ROUTE ? (
                      <NavLink
                        className={({isActive}) => {
                          return ["nav-link", isActive ? "custom-active-link" : ""].join(" ")
                        }}
                        aria-current="page"
                        to={headerLink.redirectLink}
                        end
                      >
                        {headerLink.title}
                      </NavLink>
                    ) : null}
                    {headerLink.actionType === HeaderElementType.BUTTON ? (
                      <button
                        className="btn btn-primary rounded-pill"
                        onClick={() => {
                          navigate(headerLink.redirectLink)
                        }}
                      >
                        {headerLink.title}
                      </button>
                    ) : null}
                    {headerLink.actionType === HeaderElementType.LINK ? (
                      <a className="nav-link" href={headerLink.redirectLink} target="_blank" rel="noreferrer">
                        {headerLink.title}
                      </a>
                    ) : null}
                    {headerLink.actionType === HeaderElementType.DRPODOWN ? (
                      <>
                        <a
                          className="nav-link dropdown-toggle"
                          href="." data-bs-toggle="dropdown" aria-expanded="false"
                        >
                          Resources
                        </a>
                        <ul className="resources-dropdown dropdown-menu dropdown-center">
                          {headerLink.dropdown && headerLink.dropdown.map((header, index) => (
                            <li key={index}>
                              <a
                                className="dropdown-item" href={header.redirectLink} target="_blank"
                                rel="noreferrer"
                              >
                                {header.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : null}
                  </li>
                ))
                :
                AuthenticatedHCPHeader.map((headerLink, index) => (
                  <li
                    className={`nav-item ${headerLink.actionType === HeaderElementType.DRPODOWN ? 'dropdown' : ''}`}
                    key={index}
                  >
                    {headerLink.actionType === HeaderElementType.ROUTE ? (
                      <NavLink
                        className={({isActive}) => {
                          return ["nav-link", isActive ? "custom-active-link" : ""].join(" ")
                        }}
                        aria-current="page"
                        to={headerLink.redirectLink}
                        end
                      >
                        {headerLink.title}
                      </NavLink>
                    ) : null}
                    {headerLink.actionType === HeaderElementType.BUTTON ? (
                      <button
                        style={{background: 'background-color-5'}}
                        className="btn btn-primary rounded-pill"
                        onClick={() => {
                          navigate(headerLink.redirectLink)
                        }}
                      >
                        {headerLink.title}
                      </button>
                    ) : null}
                    {headerLink.actionType === HeaderElementType.LINK ? (
                      <a className="nav-link" href={headerLink.redirectLink} target="_blank" rel="noreferrer">
                        {headerLink.title}
                      </a>
                    ) : null}
                    {headerLink.actionType === HeaderElementType.DRPODOWN ? (
                      <>
                        <a className="nav-link dropdown-toggle" href="." data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Resources
                        </a>
                        <ul className="resources-dropdown dropdown-menu dropdown-center" style={{top:'53px'}}>
                          {headerLink.dropdown && headerLink.dropdown.map((header, index) => (
                            <li key={index}>
                              <a className="dropdown-item" href={header.redirectLink} target="_blank" rel="noreferrer">
                                {header.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : null}
                  </li>
                ))
              }
            </>
          ) : null}
        </ul>

          <div className="text-start text-lg-end">
              <span><i className="bi bi-telephone"></i> {PHONE_NUMBER}</span>
              <span className="ps-3"><i className="bi bi-printer"></i> {FAX_NUMBER}</span>
              <ul className="navbar-nav justify-content-end">
                  {userData?.is_password_changed ? (
                      <>
                          {isManufacturer ?
                              <>
                                  <li className="nav-item dropdown">
                                      <a className="nav-link dropdown-toggle" href="." data-bs-toggle="dropdown"
                                         aria-expanded="false" role="button">
                                          {userData?.name}
                                      </a>
                                      <ul className="dropdown-menu dropdown-menu-end">
                                          <li><Link className="dropdown-item text-black" to='/users/updateuser'>Account
                                              Settings</Link></li>
                                          <li>
                                              <hr className="dropdown-divider"/>
                                          </li>
                                          <li><a className="dropdown-item text-black" href="."
                                                 onClick={logoutUser}>Logout</a></li>
                                      </ul>
                                  </li>
                              </> :
                              <>
                                  <DropdownStatusColorMeaning/>
                                  <li className="nav-item dropdown">
                                      <a className="nav-link dropdown-toggle" href="." data-bs-toggle="dropdown"
                                         aria-expanded="false"
                                         role="button">
                                          {userData?.name}
                                      </a>
                                      <ul className="dropdown-menu dropdown-menu-end">
                                          <li><Link className="dropdown-item text-black" to="/users/updateuser">Account
                                              Settings</Link></li>
                                          <li><Link className="dropdown-item text-black" to="/users/new">Add User</Link>
                                          </li>
                                          <li><Link className="dropdown-item text-black" to="/doctors/new">Add
                                              Provider</Link></li>
                                          <li><Link className="dropdown-item text-black" to="/pages/baa">BAA</Link></li>
                                          <li>
                                              <hr className="dropdown-divider"/>
                                          </li>
                                          <li><a className="dropdown-item text-black" href="."
                                                 onClick={logoutUser}>Logout</a></li>
                                      </ul>
                                  </li>
                              </>
                          }
                      </>
                  ) : (
                      <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="." data-bs-toggle="dropdown"
                             aria-expanded="false"
                             role="button">
                              {userData?.name}
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end">
                              <li><a className="dropdown-item text-black" href="." onClick={logoutUser}>Logout</a></li>
                          </ul>
                      </li>
                  )}
              </ul>
          </div>
      </div>
    </nav>
  );
};

export default AuthHeader;
