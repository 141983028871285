import { FC } from "react";
import ShowPatientInformation from "../addCase/showPatientInformation";
import ShowPatientAddress from "../addCase/showPatientAddress";
import ShowPrescriberInformation from "../addCase/showPrescriberInformation";
import ShowInsuranceInformation from "../addCase/showInsuranceInformation";
import { CaseStepThreeProps } from "./types";

const CaseStepThree: FC<CaseStepThreeProps> = ({ getValues }): JSX.Element => {
   const { insurances_attributes } = getValues();

  return (
    <div className="">
      <ShowPatientInformation getValues={getValues} />

      <ShowPatientAddress getValues={getValues} />

      <ShowPrescriberInformation getValues={getValues} />

      {insurances_attributes ? insurances_attributes.map((item, index) => (
        <ShowInsuranceInformation key={index} id={index+1} insurance={item} />
      )) : null}

      <p className="alert alert-warning rounded-1" role="alert">
        By signing below, I certify that I have obtained all necessary federal and state authorizations
         and consents from my patient to allow me to release health information to Reprise Reimbursement
          Resources and its contracted third parties to [1] 
          supply information to the insurer of the above named patient [2] 
          verify benefits for Reprise products, [3] 
          agree to the Business Associate Agreement as presented at <a className="m-0 p-0 btn btn-link link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover" href="/pages/baa">baa.reprisereimbursement.com</a>
      </p> 
    </div>
  );
};

export default CaseStepThree;
