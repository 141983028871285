import {FC, useContext} from "react";
import {AuthContext} from "../../context/authContext";
import {format} from "date-fns";

const BAAContent: FC = (): JSX.Element => {
  const { userData } = useContext(AuthContext);

  return (
    <>
      <div className="p-0" style={{fontFamily: "Arial, sans-serif" }}>
        <h1 className="text-center m-4">BUSINESS ASSOCIATE AGREEMENT</h1>
        <p>
          THIS BUSINESS ASSOCIATE AGREEMENT (this “Agreement”) is entered into as of{" "}
          <strong>{userData && userData.practice_created_date && userData.practice_created_date.length > 0 ? format(userData?.practice_created_date, "yyyy-MM-dd") : "[ ]"}</strong> (the “Effective Date”) by and between{" "}
          <strong>{userData && userData.practice_name ? userData.practice_name : '[ ]'}</strong> (“Covered Entity” or “CE”) with an address
          of <strong>{userData && userData.address ? userData.address : "[ ]"}</strong> and COPILOT Provider Support Services Inc.
          (“Business Associate” or “BA”) with an address of 601 S. Lake Destiny Rd.,
          Maitland, FL 32751.
        </p>
        <p>
          WHEREAS the “Covered Entity” may provide health and/or counseling services and
          maintains certain confidential protected health information and records in
          written and electronic form concerning its clients; and
        </p>
        <p>
          WHEREAS the “Business Associate” provides Internet-based case management
          services to health clinicians and has agreed to provide use of the system to CE.
        </p>
        <p>
          WHEREAS CE and BA are committed to conducting all of their business in
          compliance with all applicable federal, state, and local statutes, regulations,
          rules, and policies including, but not limited to, the Health Insurance
          Portability and Accountability Act (“HIPAA”) of 1996, the privacy rule and
          regulations enacted under its mandate including all changes and amendments to
          the HIPAA Privacy and Security Rules caused by the enactment of the Health
          Information Technology for Economic and Clinical Health Act (“HITECH”) as part
          of the American Recovery and Reinvestment Act of 2009 and relevant rules and
          regulations (collectively “the HIPAA rules”);
        </p>
        <p>
          WHEREAS in the course of the performance of the services, BA and its shareholders,
          directors, officers, employees, subcontractors, and agents (the “Agents”) will
          be provided with access to individually identifiable health information
          including demographic information collected from individuals or otherwise created
          or received by CE which relates to the past, present, or future health or
          condition of such individuals, the provision of health care to such individuals
          or the past, present, or future payment for the provision of health care to an
          individual, which information identifies such individuals or with respect to which
          there is a reasonable basis upon which to believe that the information can be
          used to identify such individuals (collectively the “Protected Health
          Information” or “PHI”);
        </p>
        <p>
          WHEREAS BA may also be provided access to electronic PHI (“EPHI”) as defined in
          HIPAA in the course of performing the services; and
        </p>
        <p>
          WHEREAS CE is willing to provide BA and its Agents with access to PHI and EPHI
          such that BA can perform the services provided, BA executes and complies with
          this Agreement as required by the HIPAA rules.
        </p>


        <p>
          <b>Definitions.</b> Capitalized terms herein shall have the specific meaning assigned within this
          Agreement or, if no meaning is assigned herein, the meaning set forth in HIPAA.
        </p>

        <p><b>Permitted and Required Uses and Disclosures.</b>
          BA agrees to use and/or disclose PHI and EPHI received from or created or
          received on behalf of CE only as necessary for the purpose of adequately
          rendering the services for CE except as otherwise permitted herein.
        </p>

        <p><b>General Privacy and Security Compliance.</b>
          BA shall maintain and safeguard the privacy, security, and confidentiality of
          all PHI as well as the confidentiality, availability, and integrity of all EPHI
          received from or created or received by BA on behalf of CE in connection with
          the provision of the services. This will be done in accordance with the
          provisions of HIPAA as amended and all applicable federal, state, and local
          statutes, regulations, and policies regarding the confidentiality of health
          information.
        </p>

        <p><b>Privacy and Security Obligations.</b> As required by HIPAA and/or HITECH, BA will:</p>
        <ul style={{listStyle: "none" }}>

          <li>
            <strong>i.</strong> Not use or further disclose PHI or EPHI other than as
            permitted or required by this Agreement and HIPAA for performance of the
            services;
          </li>
          <li>
            <strong>ii.</strong> Use appropriate safeguards to prevent the use or disclosure
            of PHI and EPHI other than as permitted or required by this Agreement and
            HIPAA for performance of the services or as required by law;
          </li>
          <li>
            <strong>iii.</strong> Use administrative, physical, and technical safeguards to
            reasonably and appropriately protect the confidentiality, integrity, and
            availability of EPHI;
          </li>
          <li>
            <strong>iv.</strong> Promptly report to CE any use or disclosure of PHI not
            permitted or required by this Agreement for performance of the services or as
            required by law or any Security Incident regarding EPHI as contemplated in
            HIPAA of which BA becomes aware;
          </li>
          <li>
            <strong>v.</strong> Notify CE in the event BA discovers an unauthorized
            acquisition, access, use, or disclosure (collectively “Breach”) of Unsecured
            PHI by any person, including employees and Agents of BA, except where: (i)
            such Breach was by an employee or person acting under the authority of BA and
            was unintentional, made in good faith, and within the course of employment, and
            the information was not further acquired, accessed, used, or disclosed; or
            (ii) an inadvertent disclosure was made by an individual otherwise authorized
            to access PHI at a facility operated by BA to another individual at the same
            facility and the information was not further acquired, accessed, used, or
            disclosed; such notification shall include the identity of each individual
            whose PHI has been or is reasonably believed to have been accessed, acquired,
            or disclosed and shall be made without unreasonably delay but in no case later
            than sixty (60) calendar days after discovery;
          </li>
          <li>
            <strong>vi.</strong> Be responsible for demonstrating that such notification to
            CE was properly made in light of the fact that any Breach is considered
            “discovered” as of the first day on which such Breach becomes known to BA,
            including any person (other than the individual committing the Breach) that is
            an employee, officer, or other Agent of BA or the first day upon which BA
            should have known of the Breach;
          </li>
          <li>
            <strong>vii.</strong> Ensure that any Agents, including subcontractors of BA or
            of any Agent, if any, to whom BA or CE provides PHI or EPHI received from or
            created or received by BA on behalf of CE agree in writing to the same
            restrictions and conditions that apply to BA with respect to such PHI and
            EPHI;
          </li>
          <li>
            <strong>viii.</strong> Make available to CE PHI and/or EPHI for inspection and
            copying in accordance with Section 164.524 of HIPAA (45 CFR 164.524) or for
            electronic transmission as contemplated in HITECH where BA or CE maintains
            an Electronic Health Record (“EHR”);
          </li>
          <li>
            <strong>ix.</strong> Make available to CE PHI for amendment and incorporate any
            amendments to PHI if appropriate in accordance with Section 164.526 of HIPAA
            (45 CFR 164.526);
          </li>
          <li>
            <strong>x.</strong> Make available to CE information in its possession required
            to provide an accounting of disclosures to participants in accordance with
            Section 164.528 of HIPAA (45 CFR 164.528), including accountings of
            disclosures made through an EHR as and if applicable in accordance with
            relevant rules and regulations;
          </li>
          <li>
            <strong>xi.</strong> Make BA’s internal practices, books, and records relating
            to the use and disclosure of PHI received from or created or received by BA
            on behalf of CE available to the Secretary of Health and Human Services
            (“HHS”) for purposes of determining CE’s compliance with HIPAA and advise CE
            immediately upon receipt of any such request;
          </li>
          <li>
            <strong>xii.</strong> At termination of this Agreement, extend the protections of
            this Agreement to the PHI and EPHI which will be retained by BA and limit
            further uses and disclosures to those purposes authorized by the HIPAA rules
            and this Agreement.
          </li>
        </ul>

        <p><b>Other Uses and Disclosures.</b>Unless otherwise expressly limited by this Agreement, BA may also:</p>
        <ul style={{listStyle: "none" }}>

          <li>
            <strong>i.</strong> Use PHI and EPHI for the proper management and administration
            of BA or to carry out the legal responsibilities of BA;
          </li>
          <li>
            <strong>ii.</strong> Disclose PHI and EPHI for the proper management and
            administration of BA provided that such disclosures are required by law or
            that BA obtains reasonable assurances from the person to whom the
            information is disclosed that the PHI and EPHI will remain confidential and
            will be used or further disclosed only as required by law or for the purpose
            for which it was disclosed to that person; and that the person to which it
            is disclosed will notify BA of any instances of which it is aware in which
            the confidentiality of the PHI and EPHI has been Breached;
          </li>
          <li>
            <strong>iii.</strong> Use PHI and EPHI to provide Data Aggregation services as
            permitted by 45 CFR 164.504(e)(2)(i)(B).
          </li>
        </ul>

        <b>Obligations of CE</b>
        <ul style={{listStyle: "none" }}>

          <li>
            <strong>i.</strong> CE shall notify its patients and BA of its privacy
            practices and restrictions as follows:
            <ul>
              <li>
                a. Provide BA with a Notice of Privacy Practices that CE produces in
                accordance with 45 CFR 164.520 and distributes to its patients as well as
                any changes to such Notice;
              </li>
              <li>
                b. Provide BA with any changes in or revocation of permission by any
                individual to use or disclose PHI if such changes may affect BA’s
                permitted and required uses and disclosures under this Agreement; and
              </li>
              <li>
                c. Notify BA of any restriction on the use or disclosure of PHI that CE
                may agree to in accordance with Section 164.522 of HIPAA if such
                agreement may affect BA’s permitted or required uses and disclosures under
                this Agreement.
              </li>
            </ul>
          </li>
          <li>
            <strong>ii.</strong> CE shall not request BA to use or disclose PHI or EPHI in
            any manner that would not be permissible under HIPAA if done by CE.
          </li>
          <li>
            <strong>iii.</strong> CE shall be solely responsible for making any decisions
            regarding and for all administrative actions concerning the exercise of any
            individual’s rights under Sections 164.524 through 164.528 of the HIPAA
            rules.
          </li>
        </ul>


        <p><b>De-Identification. </b>
          Notwithstanding anything herein to the contrary, BA may store, analyze, access,
          and use components of PHI and EPHI that have been “De-identified” as defined by
          the HIPAA rules and that do not contain individually identifiable health
          information, provided that any such use is then consistent with applicable law.
        </p>

        <b>Breach of Agreement; Termination</b>
        <ul style={{listStyle: "none" }}>

          <li>
            <strong>i.</strong> In the event that either party becomes aware of an act or
            omission of the other party that constitutes a material breach or violation
            of the parties’ obligations under this Agreement, which breach is not cured
            within fifteen (15) days after notice is provided to the breaching party, this
            Agreement may be terminated by the non-breaching party for cause. Further, if
            in the non-breaching party’s discretion, more than one breach occurs which
            constitutes a pattern or practice of conduct or breach of the Agreement by
            the breaching party, the non-breaching party may terminate this Agreement
            immediately without prior notice or cure period.
          </li>
          <li>
            <strong>ii.</strong> If upon breach of this Agreement by either party it is not
            feasible in the opinion of the non-breaching party to terminate this Agreement,
            the non-breaching party shall notify HHS of such a situation.
          </li>
        </ul>


        <p><b>Re-Negotiation.</b>
          The parties agree to negotiate in good faith any modification to this Agreement
          that may be necessary or required to ensure consistency with amendments to and
          changes in applicable federal and state laws and regulations, including but not
          limited to regulations promulgated pursuant to HIPAA.
        </p>

        <p><b>Breach Notification.</b>In complying with the obligations set forth above:</p>
        <ul style={{listStyle: "none" }}>
          <li>
            <strong>i.</strong> BA shall use appropriate safeguards and, as of September
            23, 2013, comply where applicable with the HIPAA Security Rule with respect
            to EPHI to prevent use or disclosure of the information other than as provided
            for by this Agreement.
          </li>
          <li>
            <strong>ii.</strong> BA will mitigate any harmful effect of a use or disclosure
            of PHI by BA in violation of the requirements of this Agreement.
          </li>
          <li>
            <strong>iii.</strong> BA will promptly report to CE:

            (i) any use or disclosure of PHI not provided for by this Agreement of
            which it becomes aware in accordance with 45 CFR §164.504(e)(2)(ii)(C); and/or

            (ii) any Security Incident of which BA becomes aware in accordance with 45
            CFR §164.314(a)(2)(i)(C).

            BA will notify CE within ten (10) business days after BA’s discovery of any
            Security Incident that involves an unauthorized acquisition, access, use, or
            disclosure of PHI. BA agrees that such notification will meet the requirements
            of the HIPAA Breach Notification Rule set forth in 45 CFR §164.410. BA will
            provide to the CE the names and contact information of all individuals whose
            PHI was or is believed to have been involved, all other information reasonably
            requested by the CE to enable the CE to perform and document a risk assessment
            in accordance with the HIPAA Breach Notification Rule with respect to the
            Security Incident to determine whether a Breach occurred, and all other
            information reasonably necessary to provide notice to individuals, the Department
            of HHS, and/or the media in accordance with the HIPAA Breach Notification Rule.
            In the event of a Security Incident that is required to be reported under this
            Section iii, upon making such disclosure to the CE, BA shall have fulfilled its
            obligation under this Agreement regarding Breach notification to any individuals
            whose PHI or EPHI has or may have been involved in any Breach of the privacy
            and security provisions of this Agreement.
          </li>
          <li>
            <strong>iv.</strong> In accordance with 45 CFR 164.502(e)(1)(ii) and 45 CFR
            164.308(b)(2), BA will ensure that any subcontractors that create, receive,
            maintain, or transmit PHI on behalf of BA agree to the same restrictions and
            conditions in writing that apply through this Agreement to BA with respect to
            such PHI. This includes, but is not limited to, the requirement that
            subcontractors comply with the HIPAA Security Rule to the extent they create,
            receive, maintain, or transmit EPHI on behalf of BA.
          </li>
        </ul>
        <p>
          This Agreement supersedes any and all agreements in place regarding such
          activity and reporting obligations.
        </p>

        <p>
          IN WITNESS WHEREOF, each of the undersigned has caused this Agreement to be duly
          executed effective as of the Effective Date.
        </p>

        <div className="row">
          <div className="col-5 ">
            <h6 className="mb-4 mt-4">[COVERED ENTITY]</h6>
            <p>By: ________________________________</p>
            <p>Name: ______________________________</p>
            <p>Title: ______________________________</p>
            <p>Date: ______________________________</p>
          </div>

          <div className="col-5">
            <h6 className="mb-4 mt-4">COPILOT PROVIDER SUPPORT SERVICES LLC.</h6>
            <p>By: ________________________________</p>
            <p>Name: ______________________________</p>
            <p>Title: ______________________________</p>
            <p>Date: ______________________________</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BAAContent;
