import { FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import { format } from "date-fns";
import Select, { GroupBase, MultiValue, OptionsOrGroups } from "react-select";
import { useDebouncedCallback } from "use-debounce";

import HubInputField from "../inputType";
import useFetch from "src/hooks/useFetch";

import { APIEndpoints } from "src/types/apiTypes";
import { DiagnosisResponseProps, CaseStepOneProps } from "./types";
import { dataProduct, dataSitofCare } from "src/constants/contact";
import { STATIC_CPT_CODES } from "src/constants";



const CaseStepOne: FC<CaseStepOneProps> = ({ control, isVisible, watch, setValue, getValues }): JSX.Element => {
  const diagnosisCode = watch("prescription_attributes.diagnosis_code");
  const shipTo = watch("case_attributes.ship_to");
  const drugName = watch("prescription_attributes.drug_name");
  const cpt_code = watch("procedure_attributes.cpt_code")


  /**
   * @GetProcedureListing API to call getProcedureListing
   */
  // const { data } = useFetch<ProcedureRequestProps>(APIEndpoints.GET_PROCEDURE_DROPDOWN);


  /**
   * @SearchDiagnosisBySearchValue search by API for diagnosis
   * @first initialize useFetch() with diagnosis
   * @second search diagnosis by search term
   */
  const { loading: diagnosisLoader, data: rfcListing, mutate: getDiagnosis, removeErrorAndData: clearDiagnosisData } =
    useFetch<DiagnosisResponseProps>(APIEndpoints.GET_DIAGNOSIS_DROPDOWN);

  /** @second */
  const searchDiagnosis = (search: string) => {
    if (typeof search === "string" && search.length >= 2) {
      clearDiagnosisData();
      getDiagnosis(null, null, { search });
    }
  };

  const debounced = useDebouncedCallback(searchDiagnosis, 1500);

  useEffect(() => {
    if (diagnosisCode || (rfcListing && rfcListing.data)) {
      setTimeout(() => clearDiagnosisData(), 150);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnosisCode]);


  // // Logic to determine secondary dropdown options based on selected primary code
  const getSecondaryOptions = (primaryCode: number) => {
    switch (primaryCode) {
      case 15271:
        return [{ value: '15272', label: '15272 (Optional)' }];
      case 15273:
        return [{ value: '15274', label: '15274 (Optional)' }];
      case 15275:
        return [{ value: '15276', label: '15276 (Optional)' }];
      case 15277:
        return [{ value: '15278', label: '15278 (Optional)' }];
      default:
        return [];
    }
  };

  useEffect(() => {
    if (cpt_code) {
      const secondaryValues = getSecondaryOptions(Number(cpt_code));
      const isSecondaryValueExists = secondaryValues.find(
        (cpt_code) => cpt_code.value === getValues("procedure_attributes.secondary_cpt_code")
      );
      if (!isSecondaryValueExists)
        setValue("procedure_attributes.secondary_cpt_code","")
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpt_code]);


  return (
    <div className={`card mb-3 ${!isVisible ? 'd-none' : ''}`}>
      <div className="card-header">
        <h4 className="m-0">Getting Started</h4>
      </div>

      <div className="card-body">
        <p>
          In order to process your request for benefit investigation, the following
          information must be completed. All information is kept confidential according
          to HIPAA guidelines. Please fill out the fields below as completely as possible.
          Missing fields may result in a delay to processing patient benefits in a timely manner.
        </p>

        <div className="row">
          <div className="col-lg-6">
            <Controller
              name="procedure_attributes.cpt_code"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId="procedure_attributes.cpt_code"
                  inputLabel="Procedure (CPT)"
                  isRequired={true}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  field={field}
                  error={error}
                  isDropdown={true}
                  isSearchable={false}
                  inputPlaceholder="Select a Procedure ..."
                  dropdownData={
                    (STATIC_CPT_CODES ?
                      STATIC_CPT_CODES.map(
                        (procedure) => ({
                          value: procedure.value,
                          label: `${procedure.value}`
                        })
                      ) : []
                    ) as unknown as OptionsOrGroups<string, GroupBase<string>>
                  }
                />
              )}
            />
            {cpt_code && (
              <div className="form-group">
                <Controller
                  name="procedure_attributes.secondary_cpt_code"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <HubInputField
                      inputId="procedure_attributes.secondary_cpt_code"
                      inputLabel="Secondary Procedure (CPT)"
                      isRequired={false}
                      isRowBased={true}
                      isRowInsideAnother={true}
                      field={field}
                      error={error}
                      isDropdown={true}
                      isSearchable={false}
                      inputPlaceholder="Select a Secondary CPT Code ..."
                      dropdownData={
                        (
                          getSecondaryOptions(Number(cpt_code))
                        ) as unknown as OptionsOrGroups<string, GroupBase<string>>
                      }

                    />

                  )}
                />
              </div>
            )}
          </div>
          <div className="col-lg-6">
            <Controller
              name="prescription_attributes.drug_name"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId="prescription_attributes.drug_name"
                  inputLabel="Product"
                  isRequired={true}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  field={field}
                  error={error}
                  isDropdown={true}
                  isSearchable={false}
                  inputPlaceholder="Select a Product ..."
                  dropdownData={
                    (dataProduct ?
                      dataProduct.map(
                        (procedure) => ({ value: procedure.value, label: procedure.name })
                      ) : []
                    ) as unknown as OptionsOrGroups<string, GroupBase<string>>
                  }
                />
              )}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="row mb-3">
              <label htmlFor="prescription_attributes.diagnosis_code" className="form-label col-lg-4 col-md-4 col-12">
                Diagnosis Code *
              </label>
              <div className="col-lg-8 col-md-8 col-12">
                <Controller
                  name="prescription_attributes.diagnosis_code"
                  control={control}
                  defaultValue={[]}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Select
                        options={
                          (rfcListing && rfcListing.data && rfcListing.data.length > 0 ?
                            rfcListing.data.map((option) => ({ value: option.code, label: option.fullname }))
                            : []) as unknown as OptionsOrGroups<string, GroupBase<string>>
                        }
                        id="prescription_attributes.diagnosis_code"
                        isSearchable={true}
                        isMulti={true}
                        placeholder="Enter Diagnosis Code"
                        {...field}
                        noOptionsMessage={() => diagnosisLoader ? "Loading..." : "Please enter 2 or more characters"}
                        value={
                          field.value.map((item) => ({
                            value: item,
                            label: item,
                          })) as unknown as MultiValue<unknown>
                        }
                        onChange={
                          (val) => {
                            field.onChange(val.map((item: unknown) => (item as { value: string; }).value))
                          }
                        }
                        onInputChange={debounced}
                      />

                      {error && error.message ? (
                        <div className="text-danger text-small mt-2">{error.message}</div>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <Controller
              name="prescription_attributes.qty"
              control={control}
              defaultValue={0}
              render={({ field, fieldState: { error } }) => (
                <div className="row mb-3">
                  <label
                    htmlFor="prescription_attributes.qty"
                    className="form-label col-lg-4 col-md-4 col-12"
                  >
                    Qty *
                  </label>
                  <div className="col-lg-8 col-md-8 col-12">
                    <div className="input-group">
                      <input
                        type="text" id="prescription_attributes.qty"
                        style={{ zIndex: 0 }}
                        className="form-control" disabled={drugName === ""}
                        {...field}
                      />
                      <span className="input-group-text fw-bold" id="basic-addon2">Number of Applications</span>
                    </div>
                    {error && error.message ? (
                      <div dangerouslySetInnerHTML={{ __html: error.message }} className="text-danger text-small mt-2"></div>
                    ) : null}
                  </div>
                </div>
              )}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <Controller
              name="prescription_attributes.direction"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId="prescription_attributes.direction"
                  isRowBased={true}
                  isRequired={false}
                  isRowInsideAnother={true}
                  field={field}
                  inputLabel="Directions"
                  inputPlaceholder=""
                  error={error}
                />
              )}
            />
          </div>
          <div className="col-lg-6">
            <Controller
              name="case_attributes.ship_to"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  isRequired={true}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  inputId="case_attributes.ship_to"
                  inputLabel="Site of Care"
                  inputPlaceholder="Select Site of Care..."
                  field={field}
                  error={error}
                  isDropdown={true}
                  isSearchable={false}
                  dropdownData={
                    (dataSitofCare ?
                      dataSitofCare.map(
                        (siteofCare) => ({ value: siteofCare.value, label: `${siteofCare.name}` })
                      ) : []
                    ) as unknown as OptionsOrGroups<string, GroupBase<string>>
                  }
                />
              )}
            />
            {shipTo === "other" && (
              <Controller
                name="case_attributes.other_ship_to" control={control} defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <HubInputField
                    inputId="case_attributes.other_ship_to"
                    isRowBased={true}
                    isRowInsideAnother={true}
                    isRequired={false}
                    field={field}
                    inputLabel="Custom Site of Care"
                    inputPlaceholder="Enter custom site of care"
                    error={error}
                  />
                )}
              />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Controller
              name="prescription_attributes.note" control={control} defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId="prescription_attributes.note"
                  isRowBased={true}
                  isRowInsideAnother={false}
                  isRequired={false}
                  field={field}
                  inputLabel="Notes"
                  inputPlaceholder="Note Shared With Patient"
                  error={error}
                />
              )}
            />
          </div>
        </div>

        <Controller
          name="prescription_attributes.written_date"
          control={control}
          defaultValue={format(new Date(), 'dd/MM/yyyy')}
          render={({ field: { value } }) => (
            <div className="form-check mt-3">
              <input
                className="form-check-input" type="checkbox"
                id="flexCheckIndeterminateDisabled"
                disabled checked
              />
              <label className="form-check-label" htmlFor="flexCheckIndeterminateDisabled">
                Anticipated Application Date ({value})
              </label>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default CaseStepOne;
