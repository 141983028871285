import { FC, useContext, useEffect, useReducer, useRef, useState } from "react";
import HighchartsReact from 'highcharts-react-official'
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

import AuthSubHeader from "src/components/authSubHeader";
import FooterText from "src/components/footerText";
import TableFilters from "src/components/caseFilters";
import RenderNextStep from "./caseItemActions";
import RequestDateOfTreatment from "src/components/modals/requestDateOfTreatment";
import CancelTreatment from "src/components/modals/cancelTreatment";

import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { BAR_CHART } from "../../constants/highCharts";
import { ToastContext } from "src/context/toastContext";
import { ToastTypes } from "src/types";
import { AuthContext } from "src/context/authContext";
import { LoaderContext } from "src/context/loaderContext";

import { CaseListAPIResponseType, CaseListErrorResponse, QueryParamsState, QueryStateActionType, QueryStateActions } from "./types";
import { CASE_HEADER_LISTING, CASE_STATUS_LISTING } from "src/constants/cases";
import { initialState, queryParamReducer } from "./reducer";
import { TreatmentListErrorResponse, TreatmentStatusUpdateApiResponse } from "../treatmentListing/types";
import TreatedOrNotTreatedTreatment from "src/components/modals/treatedOrNotTreatedTreatment";



const Dashboard: FC = (): JSX.Element => {
  const [chartOptions, setChartOptions] = useState(BAR_CHART);
  const [queryParams, dispatch] = useReducer(queryParamReducer, initialState);
  const [showRequestTreatmentModal, setDataForRequestModalTreatment] = useState<{ isModalVisible: boolean; caseId: number; prescriptionId :number }>({
    isModalVisible: false,
    caseId: 0,
    prescriptionId:0
  });
  const [cancelTreatmentModal, setDataForCancelModal] = useState<{ isModalVisible: boolean; caseId: number; prescriptionId :number }>({
    isModalVisible: false,
    caseId: 0,
    prescriptionId:0
  });
  const [treatedOrNotTreatedTreatmentModal, setDataForTreatedOrNotTreatedTreatmentModal] = useState<{ isModalVisible: boolean,caseId:number,treatment_id:number}>({
    isModalVisible: false,
    caseId : 0,
    treatment_id: 0
  });

  const { showToast } = useContext(ToastContext);
  const { destroySession } = useContext(AuthContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [selectedCaseStatus, setSelectedCaseStatus] = useState<string>("All Cases");





  const { data: caseObject, mutate: getCaseListing, error: caseError, removeErrorAndData: clearCaseState } =
    useFetch<CaseListAPIResponseType, QueryParamsState>(APIEndpoints.CASE_LIST, queryParams);

  useEffect(() => {
    if (caseError) {
      hideLoader();
      const { code, error } = caseError as unknown as CaseListErrorResponse;
      showToast([error || `Can't fetch cases details right now. Please try again in a while.`], ToastTypes.ERROR);
      if (code === 401) {
        destroySession();
        navigate(`/users/sign_in`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseError]);

  useEffect(() => {
    if (caseObject && caseObject.status) {
      hideLoader();
      if (caseObject.status === 500) {
        showToast(['No cases found against provided filters'], ToastTypes.ERROR);
      }

      const latestChartOptions = { ...chartOptions };
      latestChartOptions.series = [{
        type: 'bar',
        name: 'Value',
        data: [caseObject.pending, caseObject.investigated],
      }];

      setChartOptions(latestChartOptions);
      const chart = chartComponentRef.current?.chart;

      if (chart) {
        chart.redraw();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseObject]);

  const onClearButtonClicked = () => {
    if (caseError || caseObject) clearCaseState();
    dispatch({
      type: QueryStateActions.CLEAR_STATE,
    });
    getCaseListing(null, null, initialState);
  };

  const handleSearchWithQueryParam = (updatedState?: QueryParamsState) => {
    if (caseError || caseObject) clearCaseState();
    setLoader();
    getCaseListing(null, null, updatedState ? updatedState : queryParams);
  };





  const changeIdOrder = (value: string) => {
    const order = queryParams.direction === 'desc' ? 'asc' : 'desc';

    const action: QueryStateActionType = {
      type: QueryStateActions.CHANGE_SORT_PARAMS,
      payload: {
        direction: queryParams.sort_by === value ? order : "desc",
        sort_by: value,
      },
    };
    dispatch(action);

    const updatedState = queryParamReducer(queryParams, action);
    handleSearchWithQueryParam(updatedState);
  };

  const changePageNumber = (page: { selected: number; }) => {
    const action: QueryStateActionType = {
      type: QueryStateActions.CHANGE_PAGE,
      payload: page.selected+1,
    };
    dispatch(action);

    const updatedState = queryParamReducer(queryParams, action);
    handleSearchWithQueryParam(updatedState);
  };





  const { data: treatmentUpdateData, mutate: updatetreatment, error: treatmentUpdateError } =
    useFetch<TreatmentStatusUpdateApiResponse>(APIEndpoints.TREATMENT_STATUS_UPDATE);

  useEffect(() => {
    if (treatmentUpdateError) {
      hideLoader();
      const { code, error } = treatmentUpdateError as unknown as TreatmentListErrorResponse;
      showToast([error || `Treatment can't update now. Please try again in a while.`], ToastTypes.ERROR);
      if (code === 401) {
        destroySession();
        navigate(`/users/sign_in`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentUpdateError]);

  useEffect(() => {
    if (treatmentUpdateData && treatmentUpdateData.code) {
      getCaseListing(null, null, initialState);
      hideLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentUpdateData]);

  const onTreatedOrNotTreatedButtonClicked = (value: string, treatment_id: number) => {
    const requestBody = { status: 0 };

    if (value === "Not-Treated") {
      requestBody.status = 31;
    } else {
      requestBody.status = 22;
    }
    setLoader()
    updatetreatment(requestBody, { treatmentId: treatment_id });
  };

  // const interpretDrugName = (drugName: string, category: string) => {
  //   if (category?.includes("pdp") || category?.includes("PDP")) {
  //     return `${drugName}: Direct Purchase`;
  //   }
  //   else if (category === "pdp_medrx" || category === "PDP & Both Ben") {
  //     return `${drugName}: Patient Assistance`;
  //   }
  //   else if (category === "medrx") {
  //     return `${drugName}: Benefit Investigation`;
  //   }
  // };

  return (
    <div className="container-fluid cases-page overflow-y-auto overflow-x-auto d-flex flex-column flex-fill">
      <AuthSubHeader />

      <div className="card m-1 mt-4 flex-fill">
        <div className="card-header">
          <h3 className="text-black m-0">
            {selectedCaseStatus}
          </h3>
        </div>

        <div className="card-body p-0">
          <div className="px-3 pt-3">
            <p>The following is a list of cases. Click on the Case ID to view details of the case. Yellow cases require user attention.</p>

            <TableFilters
                dropdownData={CASE_STATUS_LISTING}
                inputPlaceholder="Search by Case ID, Patient, Provider"
                state={queryParams}
                dispatch={dispatch}
                handleSearchWithQueryParam={handleSearchWithQueryParam}
                onClearButtonClicked={onClearButtonClicked}
                onStatusChange={setSelectedCaseStatus}
            />
          </div>

          <div className="table-responsive-sm table-responsive-md table-responsive-lg">
            <table className="table table-striped table-sm mt-3">
              <thead>
              <tr className="text-center">
                {CASE_HEADER_LISTING.map((tableHeader, index) => (
                    <th key={index} className="bg-primary-gray text-white fs-6" style={{minWidth: "115px"}}
                        onClick={tableHeader.value !== '' ? () => changeIdOrder(tableHeader.value) : undefined}
                    >
                      <span
                          role={tableHeader.value === '' ? "textbox" : "button"}
                          className={tableHeader.value === '' ?
                              '' :
                              'btn text-white btn-link link-underline link-underline-opacity-0 p-0 fw-bold'
                          }
                      >
                        {tableHeader.label}
                        {queryParams.sort_by === tableHeader.value ? (
                            <i className={`bi ${queryParams.direction === 'desc' ? 'bi-caret-down-fill' : 'bi-caret-up-fill'}`}></i>
                        ) : null}
                      </span>
                    </th>
                ))}
              </tr>
              </thead>
              <tbody>
              {caseObject && caseObject.cases && caseObject.cases.length > 0 ?
                  caseObject.cases.map(kase => (
                      <tr key={kase.id} className="align-middle text-center">
                        <td>
                          <Link
                              to={{pathname: `/cases/detail/${kase.id}`}}
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title={`Patient ID: ${kase.patient ? kase.patient.external_id : ''}`}
                              className="btn btn-link link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover"
                          >
                            {kase.id}
                          </Link>
                        </td>
                        <td className="fs-6">{kase ? kase.created_at : ''}</td>
                        <td className="fs-6">{kase ? kase.drug_name : ''}</td>
                        <td className="fs-6">{kase && kase.patient ? kase.patient.firstlastname : ''}</td>
                        <td className="fs-6">{kase && kase.doctor ? kase.doctor.firstlastname : ''}</td>
                        <td className={`text-white align-middle fs-6 background-color-${kase && kase.color_idx ? kase.color_idx : 1}`}>
                          {kase ? kase.status_name : ''}
                        </td>
                        <td>
                          <RenderNextStep
                              kase={kase}
                              getCaseListing={getCaseListing}
                              showHideModalForTreatment={setDataForRequestModalTreatment}
                              setDataForCancelModal={setDataForCancelModal}
                              onTreatedOrNotTreatedButtonClicked={onTreatedOrNotTreatedButtonClicked}
                              setDataForTreatedOrNotTreatedTreatmentModal={setDataForTreatedOrNotTreatedTreatmentModal}
                          />
                        </td>
                        <td>
                          <Link to={`/cases/${kase ? kase.id : 0}/clone`} className="btn btn-success my-2">
                            <i className="bi bi-arrow-repeat"></i> Re-run
                          </Link>
                        </td>
                      </tr>
                  ))
                  : null
              }
              </tbody>
            </table>
          </div>
          {caseObject && caseObject.total_pages ? (
              <ul className="pagination justify-content-center">
                {queryParams.page !== 1 ? (
                    <li className="page-item">
                      <button
                          className="btn btn-link page-link"
                          onClick={() => changePageNumber({selected: 0})}
                      >
                        <i className="bi bi-chevron-double-left"></i> First
                      </button>
                    </li>
                ) : null}

                <li>
                  <ReactPaginate
                      nextLabel={<>Next <i className="bi bi-chevron-right"></i></>}
                      onPageChange={changePageNumber}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={caseObject.total_pages}
                      previousLabel={<><i className="bi bi-chevron-left"></i> Previous</>}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                      forcePage={queryParams.page - 1}
                  />
                </li>

                {queryParams.page !== caseObject.total_pages ? (
                    <li className={`page-item ${queryParams.page === caseObject.total_pages ? 'disabled' : ''}`}>
                      <button
                          className="btn btn-link page-link rounded-start-0"
                          onClick={() => changePageNumber({selected: caseObject.total_pages - 1})}
                      >
                        Last <i className="bi bi-chevron-double-right"></i>
                      </button>
                    </li>
                ) : null}
              </ul>
          ) : null}
        </div>
      </div>

      <RequestDateOfTreatment
          isModalVisible={showRequestTreatmentModal.isModalVisible}
          caseId={showRequestTreatmentModal.caseId}
          prescriptionId={showRequestTreatmentModal.prescriptionId}
        hideModal={() => {
          setDataForRequestModalTreatment({
            isModalVisible: false,
            caseId: 0,
            prescriptionId: 0
          });
        }}
        refreshListing={() => {
          handleSearchWithQueryParam();
        }}
      />

      <CancelTreatment
        isModalVisible={cancelTreatmentModal.isModalVisible}
        caseId={cancelTreatmentModal.caseId}
        prescriptionId={cancelTreatmentModal.prescriptionId}
        hideModal={() => {
          setDataForCancelModal({
            isModalVisible: false,
            caseId: 0,
            prescriptionId: 0
          });
        }}
        refreshListing={() => {
          handleSearchWithQueryParam();
        }}
      />

      <TreatedOrNotTreatedTreatment
        isModalVisible={treatedOrNotTreatedTreatmentModal.isModalVisible}
        dataTreatmentStates={treatedOrNotTreatedTreatmentModal}
        onTreatedOrNotTreatedButtonClick={onTreatedOrNotTreatedButtonClicked}
        hideModal={() => {
          setDataForTreatedOrNotTreatedTreatmentModal({
            isModalVisible: false,
            caseId: 0,
            treatment_id:0
          });
        }}
        refreshListing =  {() => {
          handleSearchWithQueryParam();
        }}
      />

      <FooterText footerType="black"/>
    </div>
  );
};

export default Dashboard;
